
export class Config {
	
	public static getApiUrl () {
		return 'https://www.verhoevenbv.online/api/'; 
	}
	
	constructor()
	{

	}
}