import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/authguard.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then( m => m.LogoutPageModule),
	canActivate: [AuthGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
	canActivate: [AuthGuard]
  },
  {
    path: 'select-location',
    loadChildren: () => import('./pages/select-location/select-location.module').then( m => m.SelectLocationPageModule),
	canActivate: [AuthGuard]
  },
  {
    path: 'new-location',
    loadChildren: () => import('./pages/new-location/new-location.module').then( m => m.NewLocationPageModule),
	canActivate: [AuthGuard]
  },
  {
    path: 'select-container/:id',
    loadChildren: () => import('./pages/select-container/select-container.module').then( m => m.SelectContainerPageModule),
	canActivate: [AuthGuard]
  },
  {
    path: 'select-container',
    loadChildren: () => import('./pages/select-container/select-container.module').then( m => m.SelectContainerPageModule),
	canActivate: [AuthGuard]
  },
  {
    path: 'select-waste-flow/:id',
    loadChildren: () => import('./pages/select-waste-flows/select-waste-flows.module').then( m => m.SelectWasteFlowsPageModule),
	canActivate: [AuthGuard]
  },
  {
    path: 'select-waste-flow',
    loadChildren: () => import('./pages/select-waste-flows/select-waste-flows.module').then( m => m.SelectWasteFlowsPageModule),
	canActivate: [AuthGuard]
  },
  {
    path: 'select-date/:id',
    loadChildren: () => import('./pages/select-date/select-date.module').then( m => m.SelectDatePageModule),
	canActivate: [AuthGuard]
  },
  {
    path: 'select-date',
    loadChildren: () => import('./pages/select-date/select-date.module').then( m => m.SelectDatePageModule),
	canActivate: [AuthGuard]
  },
  {
    path: 'order-note/:id',
    loadChildren: () => import('./pages/order-note/order-note.module').then( m => m.OrderNotePageModule),
	canActivate: [AuthGuard]
  },
  {
    path: 'order-note',
    loadChildren: () => import('./pages/order-note/order-note.module').then( m => m.OrderNotePageModule),
	canActivate: [AuthGuard]
  },
  {
    path: 'order-photos/:id',
    loadChildren: () => import('./pages/order-photos/order-photos.module').then( m => m.OrderPhotosPageModule),
	canActivate: [AuthGuard]
  },
  {
    path: 'order-photos',
    loadChildren: () => import('./pages/order-photos/order-photos.module').then( m => m.OrderPhotosPageModule),
	canActivate: [AuthGuard]
  },
  {
    path: 'order-review/:id',
    loadChildren: () => import('./pages/order-review/order-review.module').then( m => m.OrderReviewPageModule),
	canActivate: [AuthGuard]
  },
  {
    path: 'order-review',
    loadChildren: () => import('./pages/order-review/order-review.module').then( m => m.OrderReviewPageModule),
	canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'my-locations',
    loadChildren: () => import('./pages/my-locations/my-locations.module').then( m => m.MyLocationsPageModule)
  },
  {
    path: 'my-locations/:type',
    loadChildren: () => import('./pages/my-locations/my-locations.module').then( m => m.MyLocationsPageModule)
  },
  {
    path: 'my-location/:id',
    loadChildren: () => import('./pages/my-location/my-location.module').then( m => m.MyLocationPageModule)
  },
  {
    path: 'my-location',
    loadChildren: () => import('./pages/my-location/my-location.module').then( m => m.MyLocationPageModule)
  },
  {
    path: 'waste-flow-information/:id',
    loadChildren: () => import('./pages/waste-flow-information/waste-flow-information.module').then( m => m.WasteFlowInformationPageModule)
  },
  {
    path: 'waste-flow-information',
    loadChildren: () => import('./pages/waste-flow-information/waste-flow-information.module').then( m => m.WasteFlowInformationPageModule)
  },
  {
    path: 'edit-location/:id',
    loadChildren: () => import('./pages/edit-location/edit-location.module').then( m => m.EditLocationPageModule)
  },
  {
    path: 'waste-flow-pdf/:id',
    loadChildren: () => import('./pages/waste-flow-pdf/waste-flow-pdf.module').then( m => m.WasteFlowPdfPageModule)
  },
  {
    path: 'order-public-notes/:id',
    loadChildren: () => import('./pages/order-public-notes/order-public-notes.module').then( m => m.OrderPublicNotesPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
