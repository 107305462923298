import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';

import { Observable } from 'rxjs';

import { ToastController } from '@ionic/angular';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  
	public user: any = false;  
	public interval: any = false;  
	public moduleCount: any = {};

	constructor(
		private router: Router,
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		public toastController: ToastController, 
		private authenticationService: AuthenticationService
	) {
		this.initializeApp();
	}

	initializeApp() {
		this.platform.ready().then(() => {
			this.statusBar.styleDefault();
			this.splashScreen.hide();
			
			var self = this;

			this.authenticationService.authState.subscribe(state => {
				if (state) {
				} else {
					this.router.navigate(['login']);
				}
			});
		});
	}
	  
	private async presentToast(message) {
		const toast = await this.toastController.create({
		  message,
		  duration: 3000
		});
		toast.present();
	}
}
