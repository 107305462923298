import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { ToastController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Config } from '../models/Config';
import { User } from '../models/User';
import { AuthenticationConfig } from '../models/AuthenticationConfig';
 
@Injectable()
export class AuthenticationService {
 
  public currentActivities: any;
  public currentUser: any;
  public authenticationConfig: any;
  public _storage: any;
 
  authState = new BehaviorSubject(false);
  newUserBadge = new BehaviorSubject(false);
  activities = new BehaviorSubject(false);
  openSetUserGoals = new BehaviorSubject(false);
 
  constructor(
	private http: HttpClient,
    private router: Router,
    private storage: Storage,
    private platform: Platform,
    public toastController: ToastController
  ) {
		this.init();
		this.platform.ready().then(() => {
			this.ifLoggedIn();
		});
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }
 
  ifLoggedIn() {
	var self = this;
  }
  
  clearAuthenticationConfig () {
	let self = this;
	return this._storage.set('AUTHENTICATION_INFO', '').then((response) => {
		self.getAuthenticationConfig();
	});	  
  }
  
  getAuthenticationConfig () {
	    let self = this;
		let authenticationConfig = new AuthenticationConfig();

		return this._storage.get('AUTHENTICATION_INFO').then((response) => {
			if (response !== null) {
				authenticationConfig.setAccessToken(response.accessToken);
				authenticationConfig.setRefreshToken(response.refreshToken);
				authenticationConfig.setSalt(response.salt);
			}

			self.authenticationConfig = authenticationConfig;
		});
  }
  
  saveAuthenticationConfig (authenticationConfig) {
	let self = this;
	let data = authenticationConfig.getData();
	return this._storage.set('AUTHENTICATION_INFO', data).then((response) => {
		self.getAuthenticationConfig();
	});
  }
  
  getAccessToken () {
	  try { 
		return this.authenticationConfig.getAccessToken();
	  } 
	  catch (e)
	  {
		  return '' + e.message;
	  }
  }
  
  getSeal () {
	  try { 
		return this.authenticationConfig.getSeal();
	  } 
	  catch (e)
	  {
		  return '';
	  }
  }
  
  getCurrentUser () {
	  return this.currentUser;
  }
  
  async setCurrentUser (response) {
	var self = this;

	let user = new User();
	user.setId(response.id);
	user.setFirstname(response.firstname);
	user.setLastname(response.lastname);
	user.setUsername(response.username);
	user.setActive(response.active);
	user.setData(response);
	user.setProfileImage(response.profileImage);
	
	self.currentUser = user;
  }

  saveFirebaseId (firebaseId) {
	var self = this;
	return new Promise(function(resolve, reject) 
	{
		self.http.post(Config.getApiUrl() + 'user/saveFirebaseId', { firebaseId: firebaseId, key: self.getAccessToken(), seal: self.getSeal() })
		.subscribe(data => 
		{
			let response: any = data;			
			resolve(response);
		},
		error => 
		{
			reject(error);
		});
	}); 
  }
   
  generateSalt () {
	  return Math.random().toString(36).substr(2, 60) + Math.random().toString(36).substr(2, 60);
  }
 
  login(username, password) {
	var self = this;
	return new Promise(function(resolve, reject) 
	{		
		let postData = {};
		postData['username'] = username;
		postData['password'] =  password;
		postData['salt'] = self.generateSalt();
		
		self.http.post(Config.getApiUrl() + 'user/authenticate',  postData )
		.subscribe(data => 
		{
			let response: any = data;
			
			let authenticationConfig = new AuthenticationConfig();
			authenticationConfig.setAccessToken(response.data.accessToken);
			authenticationConfig.setRefreshToken(response.data.refreshToken);
			authenticationConfig.setSalt(response.data.salt);
			
			self.saveAuthenticationConfig(authenticationConfig).then(function() {
				self._storage.set('USER_INFO', response.data).then((response) => {
				  self.setCurrentUser (response);
				  self.router.navigate(['home']);
				  self.authState.next(true);
				});
			}).catch(function(e) 
			{
				alert('error' + e.message);
			});
			
			resolve(response);
		},
		error => 
		{
			reject(error);
		});
	});
  }
 
  saveProfile(firstname, lastname, username, phone, showChangePassword, currentPassword, password1, password2) {
	var self = this
	
	return new Promise(function(resolve, reject) 
	{
		let postData = {};
		postData['firstname'] = firstname;
		postData['lastname'] = lastname;
		postData['phone'] = phone;
		postData['username'] = username;
		postData['showChangePassword'] = showChangePassword;
		postData['password1'] = password1;
		postData['password2'] = password2;
		postData['currentPassword'] = currentPassword;

		self.http.post(Config.getApiUrl() + 'user/save', { user: postData,  key:self.getAccessToken(), seal: self.getSeal()})
		.subscribe(data => 
		{
			let response: any = data;
			console.dir(response)
			resolve(response);
		},
		error => 
		{
			reject(error);
		});
	});
  }
  
  saveProfilePicture (pictureString)
  {
	var self = this
	
	return new Promise(function(resolve, reject) 
	{
		self.http.post(Config.getApiUrl() + 'user/saveProfilePicture', { profilePicture: pictureString,  key:self.getAccessToken(), seal: self.getSeal()})
		.subscribe(data => 
		{
			let response: any = data;
			resolve(response);
		},
		error => 
		{
			reject(error);
		});
	});	  
  }
  
  refreshAccessToken () {
	var self = this;
	return new Promise(function(resolve, reject) 
	{
		let authenticationConfig = self.authenticationConfig;
		
		self.http.post(Config.getApiUrl() + 'user/refreshToken', { key: authenticationConfig.getAccessToken(), refreshToken: authenticationConfig.getRefreshToken(), seal: authenticationConfig.getSeal() })
		.subscribe(data => 
		{
			let response: any = data;
			
			authenticationConfig.setAccessToken(response.data.accessToken);
			self.saveAuthenticationConfig(authenticationConfig).then(function(saveConfigResponse) {
				self._storage.set('USER_INFO', response.data).then((response) => {
				  self.setCurrentUser (response);
				  self.router.navigate(['home']);
				  self.authState.next(true);
				});
			}).catch(function(e) 
			{
				alert('error' + e.message);
			});
			
			resolve(response);
		},
		error => 
		{
			reject(error);	
		});
	}); 
  }
 
  userLoginByAccessToken () {
	var self = this;
	return new Promise(function(resolve, reject) 
	{
		self.http.post(Config.getApiUrl() + 'user/authenticateByToken', { key:self.getAccessToken(), seal: self.getSeal() })
		.subscribe(data => 
		{
			let response: any = data;
			
			self._storage.set('USER_INFO', response.data).then((response) => {
			  self.setCurrentUser (response);
			  self.router.navigate(['/home']);
			  self.authState.next(true);
			  
			  self.getUserInfo();
			});
			
			resolve(response);
		},
		error => 
		{
			reject(error);
		});
	}); 
  }
 
  getUserInfo () {
	var self = this;
	return new Promise(function(resolve, reject) 
	{
		self.http.post(Config.getApiUrl() + 'user/info', { key:self.getAccessToken(), seal: self.getSeal() })
		.subscribe(data => 
		{
			let response: any = data;
			
			self.storage.set('USER_INFO', response.data).then((response) => {
			  self.setCurrentUser(response);
			});
			resolve(response);
		},
		error => 
		{
			reject(error);
		});
	}); 
  }
  
  logout() {
	var self = this;
    this.storage.remove('USER_INFO').then(() => {
		self.clearAuthenticationConfig();
		this.router.navigate(['login']);
		this.authState.next(false);
    });
  }
 
  isAuthenticated() {
    return this.authState.value;
  }
}